import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { AuthContext } from '../shared/service/auth.context';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../features/logout/logout.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthContext, ToastService, TokenService } from '@srhk/srhk-core-library';
// import { CommonCacheService } from '../shared/service/CommonCacheService';
// import { TokenService } from '../shared/service/TokenService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  durationInSeconds = 5;
  appType="";

  isAuthenticated: boolean = false;
  showOnloadSpinner: boolean = true;
  public constructor(private router: Router,
    private route: ActivatedRoute,
    private authContext: AuthContext,
    private dialog: MatDialog,
    private tokenService: TokenService,
    private toastService: ToastService
  ) {
    

  }

  ngOnInit(): void {
    this.isAuthenticated = this.authContext.isAuthenticated;
    this.showOnloadSpinner = this.tokenService.getShowOnloadSpinner() === "true" ? true: false;
    this.tokenService.showOnloadSpinner("false");
  }

  logout() {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    this.tokenService.removeUserInfo();
    this.authContext.removeIsAuthenticated();
    window.sessionStorage.clear();
    this.toastService.showSuccess('Successfully logout', 'Success');
    this.router.navigate(["/"])
    window.location.reload();
    this.tokenService.showOnloadSpinner("false");
  }

  chooseLanguage(language: String){
    console.log("language", language);
  }

  donation(){
    this.tokenService.setIsFromDonation('true');
    this.router.navigate(["/donation"])
  }

  navigateTo(path: string){
    this.router.navigate([path])
  }

  homeNavigation() {
    this.router.navigate(['/']);
  }

  noticeNewsNavigation() {
    this.router.navigate(["/notice-news"])
  }

}
