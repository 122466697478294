import { Component } from '@angular/core';

@Component({
  selector: 'app-term-and-conditions',
  templateUrl: './term-and-conditions.component.html',
  styleUrl: './term-and-conditions.component.css'
})
export class TermAndConditionsComponent {

}
