<div class="main-content--section pbottom--30">
    <div class="container">
        <div class="post--item post--single pd--30-0">
            <div class="row">
                <div class="col-md-12">
                    <div class="post--info">
                        <div class="title">
                            <h2 class="h4">Photo Album</h2>
                            <div style="clear:both; height:10px;"></div>
                            <div style="background:#f14b05; display:block; height:3px; width:100px;"></div>
                            <div style="clear:both; height:30px;"></div>
                        </div>
                    </div>

                    <div class="post--content">
                        <ul class="nav row gutter--15">
                            <li class="col-md-3 col-xs-6 col-xxs-12">
                                <div class="post--item post--layout-1">
                                    <div class="post--img thumbnail"
                                        style=" height:250px;text-align:center;font-size: 8px;">
                                        <img src="../assets/images/RKMS_1565947140.jpg" style="height: 200px; width: 400px;">
                                        <a href="gallery.php?pro_id=7" class="btn btn-warning"
                                            style="display:inline-block; color:#000; margin-top:5px;">
                                            Dress Distribution</a>
                                    </div>
                                </div>
                            </li>
                            <li class="col-md-3 col-xs-6 col-xxs-12">
                                <div class="post--item post--layout-1">
                                    <div class="post--img thumbnail"
                                        style=" height:250px;text-align:center;font-size: 8px;">
                                        <img src="../assets/images/RKMS_1565946669.jpg" style="height: 200px; width: 400px;">
                                        <a href="gallery.php?pro_id=6" class="btn btn-warning"
                                            style="display:inline-block; color:#000; margin-top:5px;">
                                            Book Distribution to GAP</a>
                                    </div>
                                </div>
                            </li>
                            <li class="col-md-3 col-xs-6 col-xxs-12">
                                <div class="post--item post--layout-1">
                                    <div class="post--img thumbnail"
                                        style=" height:250px;text-align:center;font-size: 8px;">
                                        <img src="../assets/images/RKMS_1565946152.jpg" style="height: 200px; width: 400px;">
                                        <a href="gallery.php?pro_id=5" class="btn btn-warning"
                                            style="display:inline-block; color:#000; margin-top:5px;">
                                            Eye Camp</a>
                                    </div>
                                </div>
                            </li>
                            <li class="col-md-3 col-xs-6 col-xxs-12">
                                <div class="post--item post--layout-1">
                                    <div class="post--img thumbnail"
                                        style=" height:250px;text-align:center;font-size: 8px;">
                                        <img src="../assets/images/RKMS_1565938731.jpg" style="height: 200px; width: 400px;">
                                        <a href="gallery.php?pro_id=4" class="btn btn-warning"
                                            style="display:inline-block; color:#000; margin-top:5px;">
                                            Book Distribution</a>
                                    </div>
                                </div>
                            </li>
                            <li class="col-md-3 col-xs-6 col-xxs-12">
                                <div class="post--item post--layout-1">
                                    <div class="post--img thumbnail"
                                        style=" height:250px;text-align:center;font-size: 8px;">
                                        <img src="../assets/images/RKMS_1564135700.jpg" style="height: 200px; width: 400px;">
                                        <a href="gallery.php?pro_id=3" class="btn btn-warning"
                                            style="display:inline-block; color:#000; margin-top:5px;">
                                            Guru Purnima</a>
                                    </div>
                                </div>
                            </li>
                            <li class="col-md-3 col-xs-6 col-xxs-12">
                                <div class="post--item post--layout-1">
                                    <div class="post--img thumbnail"
                                        style=" height:250px;text-align:center;font-size: 8px;">
                                        <img src="../assets/images/RKMS_1559037955.jpg" style="height: 200px; width: 400px;">
                                        <a href="gallery.php?pro_id=2" class="btn btn-warning"
                                            style="display:inline-block; color:#000; margin-top:5px;">
                                            राष्ट्रीय संगोष्�&nbsp;ी - हिन्दी उपन्यास में लोक संस्कृति</a>
                                    </div>
                                </div>
                            </li>
                            <li class="col-md-3 col-xs-6 col-xxs-12">
                                <div class="post--item post--layout-1">
                                    <div class="post--img thumbnail"
                                        style=" height:250px;text-align:center;font-size: 8px;">
                                        <img src="../assets/images/RKMS_1552298231.jpg" style="height: 200px; width: 400px;">
                                        <a href="gallery.php?pro_id=1" class="btn btn-warning"
                                            style="display:inline-block; color:#000; margin-top:5px;">
                                            National Youth Day</a>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div class="disabled" style="text-align: center;">
                        <ul class="pagination disabled">
                            <li class="disabled"><a href="?pageno=1"><i class="fa fa-fast-backward" aria-hidden="true"
                                        title="First Page"></i></a></li>
                            <li class="disabled">
                                <a href="?pageno=5" title="Back"><i class="fa fa-step-backward"
                                        aria-hidden="true"></i></a>
                            </li>
                            <li class="disabled">
                                <a href="#" title="Next"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                            </li>
                            <li class="disabled"><a href="?pageno=6"><i class="fa fa-fast-forward" aria-hidden="true"
                                        title="Last Page"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>