<div class="main-content--section pbottom--30">
    <div class="container">
        <div class="post--item post--single pd--30-0">
            <div class="row">
                <div class="col-md-12">
                    <div class="post--info">
                        <div class="title">
                            <h2 class="h4">Important Visitors</h2>
                        </div>
                    </div>
                    <div class="post--content dropcap">
                        <img class="img-resposnive" src="../assets/images/visitors.jpg" width="100%" alt="">
						 <br>
						 <br>
						<p> The Sri Rudramsha Hanuman Kshetram Sevashrama always Invites and Welcome eminent personalities in its Annual Celebrations. Among invites were social leaders, educationists, high Govt. officials and Ministers so state and centre. </p>
						
						<!-- <ol style="margin-left:40px;">
							<li> </li>
							
						</ol> -->
					   <div class="clearfix"></div>
						<p> The Sevashrama has always been recognised by the society for its selfless services, self sacrifice, integrity, transparency and broad and scientific outlook.</p>
						<div class="clearfix"></div>
						<p>Of late, the Sevashrama has gained fame for its Eye Hospital and flood relief services. It also received a medal from the local administration in 2005 in recognition of its flood relief efforts.</p>
						
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>