<footer class="footer--section">
  <div class="footer--widgets bg--color-5">
    <div class="container">
      <div class="row AdjustRow">
        <div class="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
          <div class="widget">
            <div class="about--widget">
              <div class="content">
                <img src="../assets/images/logo-footer-1.png" class="img-responsive" />
              </div>
              <div style="border-bottom: #f14b05 2px solid; margin: 15px 0"></div>
              <ul class="nav">
                <li>
                  <i style="color: #222;" class="fa fa-envelope-o"></i>
                  <span style="color: #222; margin-right: 7px"></span>
                  <a style="color: #222;"
                    href="mailto:contact&#64;srirudramshahanumankshetram.org">contact&#64;srirudramshahanumankshetram.org</a>
                </li>
               
                <li>
                  <i style="color: #222;" class="fa fa-phone"></i>
                  <span style="color: #222; margin-right: 7px">Office Phone :</span><a style="color: #222;" href="callto:070660 06007">+91
                    70660 06007</a>
                </li>

              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
         
        </div>
        <div class="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
          <div class="widget">
            <div class="widget--title">
              <h2 class="h4">Quick Link</h2>
              <i class="icon fa fa-bullhorn"></i>
            </div>
            <div class="links--widget">
              <ul class="nav">
                <li>
                  <a style="color: #222;" href="term-and-conditions" class="fa-angle-right">Disclaimers</a>
                </li>
                <li>
                  <a style="color: #222;" href="cancellation-and-refund" class="fa-angle-right">Refund & Cancellation</a>
                </li>
                <li>
                  <a style="color: #222;" href="privacy-policy" class="fa-angle-right">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
          <div class="widget">
            <div class="widget--title">
              <h2 class="h4">Recruitment</h2>
              <i class="icon fa fa-user-o"></i>
            </div>
            <div class="links--widget">
              <ul class="nav">
                <li>
                  <a style="color: #222;" href="#" class="fa-angle-right">Office Staff Recruitment</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer--copyright bg--color-3">
    <div class="social--bg bg--color-1"></div>
    <div class="container">
      <p class="text float--left" style="color: #f14b05">
        © 2024 <a href="#">Sri Rudramsha Hanuman Kshetram, Kukatpally, Hyderabad</a>.
        &nbsp;&nbsp;All Rights Reserved.
      </p>
      <ul class="nav social float--right">
        <li>
          <a href="https://www.youtube.com/@srirudramshahanumankshetram" target="_blank"><i
              class="fa fa-youtube"></i></a>
        </li>
      </ul>
      <ul class="nav links float--right">
        <li style="color: #ccc"><a>Powered by Banjara Technology</a></li>
      </ul>
    </div>
  </div>
</footer>