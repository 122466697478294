import { Component } from '@angular/core';

@Component({
  selector: 'app-important-visitor',
  templateUrl: './important-visitor.component.html',
  styleUrl: './important-visitor.component.css'
})
export class ImportantVisitorComponent {

}
