<div class="wrapper">
    <header class="header--section header--style-1">
        <div class="header--topbar bg--color-2">
            <div class="container">
                <div class="float--left float--xs-none text-xs-center">
                    <ul class="header--topbar-info nav">
                        <li style="border-right:none; font-size:15px; text-shadow:#fff 1px 1px 0; font-weight:bold;">
                            “आत्मनो मोक्षार्थं जगत्द्धिताय च” , <span>‘For one's own salvation and for the welfare
                                of the world’</span></li>
                    </ul>
                </div>
                <div class="float--right float--xs-none text-xs-center">
                    <ul class="header--topbar-action nav">

                        <li style="background:#f14b05;"><a (click)="navigateTo('/contact')"><i class="fa fa-phone"></i>
                                Contact</a>
                        </li>
                        <li style="border-right:none; background:#a70303;"><a (click)="donation()"><i
                                    class="fa fm fa-dropbox"></i>Donate To Us</a></li>
                        <li style="border-right:none; background:#f14b05;"><a (click)="navigateTo('bookAppointment')"><i
                                    class="fa fm fa-calendar"></i>Book Appointment</a></li>
                        <li style="border-right:none;"><a href="auth/login" title="Login"><i class="fa fa-sign-in"
                                    style="color:#751704"></i></a></li>
                         <li><a href="https://www.youtube.com/@srirudramshahanumankshetram" target="_blank"><i
                             class="fa fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="header--mainbar">
            <img class="images" src="../assets/images/srhk-banner13.png" alt="" />
        </div>
        <div class="header--navbar style--1 navbar bd--color-1 bg--color-1">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#headerNav" aria-expanded="false" aria-controls="headerNav"> <span
                            class="sr-only">Toggle Navigation</span> <span class="icon-bar"></span> <span
                            class="icon-bar"></span> <span class="icon-bar"></span>
                    </button>
                </div>
                <div id="headerNav" class="navbar-collapse collapse float--left">

                    <ul class="header--menu-links nav navbar-nav" data-trigger="hoverIntent">
                        <li> <a (click)="navigateTo('/home')">Home</a></li>

                        <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle"
                                data-toggle="dropdown">Our Services<i class="fa flm fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle"
                                        data-toggle="dropdown">Mantra Sadana<i class="fa flm fa-angle-right"></i></a>
                                    <ul class="dropdown-menu">
                                        <li><a (click)="navigateTo('/guruMantraSadhana')">Guru Mantra Sadana</a></li>
                                        <li><a href="">Mantra Sadana</a></li>
                                        <li><a href="">Yantra Sadana</a></li>
                                    </ul>
                                </li>

                                <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle"
                                        data-toggle="dropdown">Pooja<i class="fa flm fa-angle-right"></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle"
                                                data-toggle="dropdown">Abhishekam<i
                                                    class="fa flm fa-angle-right"></i></a>
                                            <ul class="dropdown-menu">
                                                <li><a href="#">Shri Guru Devatha Abhishekam</a></li>
                                                <li><a href="#">Shri Rudra Devatha Abhishekam</a></li>
                                                <li><a href="#">Sri Kalarathri Devatha Abhishekam</a></li>
                                                <li><a href="#">Sri Rudramsha Hanuman Devatha Abhishekam</a></li>
                                            </ul>
                                        </li>
                                        <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle"
                                                data-toggle="dropdown">Homam<i class="fa flm fa-angle-right"></i></a>
                                            <ul class="dropdown-menu">
                                                <li><a href="#">Shri Guru Devatha Homam</a></li>
                                                <li><a href="#">Sri Kalarathri Devatha Homam</a></li>
                                                <li><a href="#">Sri Rudramsha Hanuman Devatha Homam</a></li>
                                                <li><a href="#">Spl Homam (Accomplishment)</a></li>
                                            </ul>
                                        </li>
                                        <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle"
                                                data-toggle="dropdown">Spl Pooja<i
                                                    class="fa flm fa-angle-right"></i></a>
                                            <ul class="dropdown-menu">
                                                <li><a href="#">Coconut Bali Pooja</a></li>
                                                <li><a href="#">Lemon Bali Pooja</a></li>
                                                <li><a href="#">Pumpkin Bali Pooja</a></li>
                                                <li><a href="#">Rakhnaanna Bali for Kali</a></li>
                                                <li><a href="#">Karya Siddhi Bali Pooja - Guru/Kali/Hanuman</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li><a href="#">Special Services</a></li>
                            </ul>
                        </li>

                        <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle"
                                data-toggle="dropdown">The Ashrama<i class="fa flm fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li><a (click)="navigateTo('/about-ashrama')">About Ashrama</a></li>
                                <li><a (click)="navigateTo('/vision')">Vision</a></li>
                                <li><a (click)="navigateTo('/important-visitor')">Important Visitors</a></li>
                            </ul>
                        </li>
                        <li style="display: none;" class="dropdown"> <a href="javascript:void(0);"
                                class="dropdown-toggle" data-toggle="dropdown">Ashrama Activities<i
                                    class="fa flm fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li><a (click)="navigateTo('/medicalInfo')">Medical</a></li>
                                <li><a (click)="navigateTo('/Educational')">Educational</a></li>
                                <li><a (click)="navigateTo('/religiousCulture')">Religious &amp; Cultural</a></li>
                                <li><a (click)="navigateTo('/reliefWelfare')">Relief &amp; Welfare</a></li>
                            </ul>
                        </li>
                        <li style="display: none;"> <a href="gallery">Gallery</a></li>
                        <li> <a (click)="navigateTo('/notice-news')" href="">Notice </a> </li>

                        <li class="dropdown" *ngIf="isAuthenticated"><a href="javascript:void(0);"
                                class="dropdown-toggle" data-toggle="dropdown">Profile<i
                                    class="fa flm fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li><a (click)="navigateTo('/userProfile')"> <i class="fa fa-user" style="color:#751704"></i>Profile</a></li>
                                <li><a (click)="navigateTo('/userOrder')" >My Booking</a></li>
                                <li><a (click)="logout()">logout</a></li>
                            </ul>
                        </li>
                        <li *ngIf="!isAuthenticated"> <a (click)="navigateTo('/auth/login')">Login </a> </li>
                        <li>
                            <div class="translateCss" id="google_translate_element"></div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </header>
</div>