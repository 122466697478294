import { Component } from '@angular/core';

@Component({
  selector: 'app-notice-and-news',
  standalone: true,
  imports: [],
  templateUrl: './notice-and-news.component.html',
  styleUrl: './notice-and-news.component.css'
})
export class NoticeAndNewsComponent {

}
