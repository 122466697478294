<br />
<br />
<div class="bg">

    <div class="card">

        <!-- <span class="card__success"><i class="fas fa-warning" style="color:red"></i></span> -->

        <h1 class="card__msg" style="color:red" >Payment Failed <i class="fas fa-exclamation-circle" style="color:red"></i></h1>
        <h2 class="card__submsg" style="color:black">Apologies for any inconvenience caused during your payment process. Please try again
            later. Thank you for your understanding.</h2>

        <div class="card__body">

            <img src="../../../../assets/images/user-profile-pic.png" class="card__avatar">
            <div class="card__recipient-info">
                <p class="card__recipient">
                    {{ this.userInfo.user.firstName }} {{
                    this.userInfo.user.middleName }} {{
                    this.userInfo.user.lastName }}
                </p>
                <p class="card__email">{{ this.userInfo?.user?.communicationId?.emailIds[0]?.uri }}</p>
            </div>

            <h1 class="card__price"><span>&#8377;</span>{{ this.order.totalAmount }}</h1>

            <div>
                <p class="card__method">Payment method</p>
                <!-- <p class="change_due">Due Amout From Patient : <span>&#8377;</span>{{dueAmountFromPatient}} </p>
                <p class="change_due">|</p> -->
                <!-- <p style="font-weight: bold;" class="change_due">Patient Due :
                    <span>&#8377;</span>{{dueAmountFromPatient}}
                </p> -->
            </div>
            <div *ngIf="order.trackingId!=null">
                <div *ngIf="paymentMethod" class="card__payment">
                    <img src="../../../../assets/images/visa_card.png" class="card__credit-card">
                    <div class="card__card-details">
                        <p class="card__card-type">Credit / debit card</p>
                        <p class="card__card-number">Visa ending in **{{
                            billRoute.billData.cashReceipt.refNumber.slice(-2) }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="order.trackingId!=null" class="card__tags">
            <span class="card__tag">Transaction ID</span>
            <span class="card__tag">#{{ paymentDetails.trackingId }}</span>
        </div>

    </div>

</div>

<br />
<br />
<br />
<br />