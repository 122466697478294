import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../../model/order';
import { BookAppointmentService } from '../book-appointment/book-appointment.service';

@Component({
  selector: 'app-book-appointment-confirmation',
  templateUrl: './book-appointment-confirmation.component.html',
  styleUrl: './book-appointment-confirmation.component.css'
})
export class BookAppointmentConfirmationComponent implements OnInit {

  order: Order;

  currentFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });

  constructor(
    public dialogRef: MatDialogRef<BookAppointmentConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public bookAppointmentService: BookAppointmentService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    if (this.data) {
      this.order = this.data;
    }
  }

  onYesClick() {
    this.dialogRef.close("proceed");
  }

formatCurrency(currency) {
    return this.currentFormatter.format(Number(currency));
  }

  onNoClick(): void {
    this.dialogRef.close("no");
  }

}
