<div id="contact">
    <form #ff="ngForm" (ngSubmit)="submit()">
        <div class="row gy-12">
            <h1 style="color: #1975bc">Book Appointment</h1>
            <p></p>
            <div>
                <ul>
                    <li>This appointment will allow you to connect with <b>Guru Viraat</b> or one of our representatives
                        to
                        discuss the problem statement or clarify any doubts you may have.</li>
                    <li>Once you book the appointment, our representative will contact you based on the details you
                        provide, 
                        so please ensure your contact information is accurate and up-to-date.</li>
                    <li>Non-refundable/cancellation policies for appointments are as follows:
                        <ol>
                       <li><b>Non-Refundable Policy:</b> Once an appointment is booked and confirmed, it is non-refundable.</li> 
                       <li><b>Cancellation Policy:</b> If you need to reschedule your appointment, please notify us at least 24 hours in advance.</li>
                    </ol>
                       <p>Please refer to our detailed cancellation policies for specific terms and conditions.</p></li>
                </ul>
                <br />
            </div>
            <div class="row gy-4">
                <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" name="firstName" placeholder="First Name"
                        [(ngModel)]="bookAppointment.firstName" required>
                    <mat-error>
                        First Name Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" placeholder="Last Name" name="lastName"
                        [(ngModel)]="bookAppointment.lastName" required>
                    <mat-error>
                        Last Name Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" placeholder="Last Name" name="userId"
                        [(ngModel)]="bookAppointment.userId">
                    <mat-error>
                        Email Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                    <span matPrefix>+91 &nbsp;</span>
                    <mat-label>Phone Number</mat-label>
                    <input matInput type="text" placeholder="Phone Number" name="phoneNumber"
                        [(ngModel)]="bookAppointment.phoneNumber" required>
                    <mat-error>
                        Phone Number Cannot be empty!
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                    <mat-label>Purpose</mat-label>
                    <input matInput type="text" placeholder="Purpose" name="purpose"
                        [(ngModel)]="bookAppointment.purpose" required>
                    <mat-error>
                        Purpose Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                    <mat-label> Appoinment Date </mat-label>
                    <input [(ngModel)]="bookAppointment.appoinmentDateTime" name="appoinmentDateTime" matInput
                        [min]="todayDate" [matDatepicker]="ad" placeholder="DD/MM/YYYY" required>
                    <mat-datepicker-toggle matSuffix [for]="ad"></mat-datepicker-toggle>
                    <mat-datepicker #ad></mat-datepicker>
                    <mat-error>
                        Appointment Date Cannot be null!
                    </mat-error>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline" class="col">
                    <mat-label>Date Of Birth</mat-label>
                    <input matInput [matDatepicker]="dob" [max]="maxDOB"
                        [(ngModel)]="doctor.appoinmentDateTime" name="birthDate" required
                        placeholder="DD/MM/YYYY">
                    <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                    <mat-datepicker #dob></mat-datepicker>
                    <mat-error>
                        You must enter Date of Birth.
                    </mat-error>
                </mat-form-field> -->

                <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12" style="display: flex;">
                    <span style="text-decoration: line-through;"> (₹) {{bookAppointment.amount }} &nbsp;&nbsp;</span>
                    <mat-label>Amount (₹)</mat-label>
                    <input matInput type="text" placeholder="Amount" name="amount" value="bookAppointment.discountAmount"
                        [(ngModel)]="bookAppointment.discountAmount"  readonly required>
                </mat-form-field>
            </div>
        </div>
        <br />
        <br />
        <div style="float: right;">
            <button mat-raised-button style="margin: 6px;" color="primary" type="submit" [disabled]="!ff.valid"> Proceed
            </button>
            <br />
            <br />
        </div>
    </form>
</div>