import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoggedInUserInfo, User } from '../../model/User';
import { ToastrService } from 'ngx-toastr';
import { AuthContext, AuthService, CommonCacheService, ToastService, TokenService } from '@srhk/srhk-core-library';
import { ActivatedRoute, Router } from '@angular/router';
import { BillPaymentService } from '../billPayment.service';
import { AddCashReceipt } from '../../model/billCashReceipt';
import { BookAppointmentService } from '../book-appointment/book-appointment.service';
import { Order } from '../../model/order';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrl: './payment-failed.component.scss'
})
export class PaymentFailedComponent implements OnInit, AfterViewInit {

  userInfo: LoggedInUserInfo;
  order: Order;

  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private toastService: ToastService,
    private toastr: ToastrService,
    public billPaymentService: BillPaymentService,
    public bookAppointmentService: BookAppointmentService
  ) {
    if (!this.authContext.isAuthenticated) {
      this.router.navigate(['auth/login']);
    }
  }
  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
    this.userInfo = this.tokenService.getUserInfo();
    this.getPaymentDetails();
  }


  getPaymentDetails() {
    const qpOrderNumber = this.route.snapshot.queryParamMap.get('orderNumber');

    const orderNumber = qpOrderNumber || this.tokenService.getRefreshToken()
    if(orderNumber)
    this.bookAppointmentService.getOrder(orderNumber).subscribe((res) => {
      this.order = res;
      this.tokenService.saveRefreshToken("");
    }, (err) => {
      console.log("An Error Occured", err);
      this.toastService.showError("An Error Occured while showing the confirmation", "Error")
    })

  }


}
