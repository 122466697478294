import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthContext, ToastService, TokenService } from '@srhk/srhk-core-library';

@Component({
  selector: 'app-inspiration-mission',
  templateUrl: './inspiration-mission.component.html',
  styleUrl: './inspiration-mission.component.css'
})
export class InspirationMissionComponent implements OnInit {

  public constructor(private router: Router,
    private route: ActivatedRoute,
    private authContext: AuthContext,
    private tokenService: TokenService,
    private toastService: ToastService
  ) {
    

  }

  ngOnInit(): void {

  }

  donation(purpose: string){
    this.tokenService.setIsFromDonation('true');
    this.tokenService.setDonationPurpose(purpose)
    this.router.navigate(["/donation"])
  }
}
