<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container" data-aos="fade-up">
    <header class="section-header">
      <h2>Contact</h2>
    </header>

    <div class="row gy-4">
      <div class="col-lg-6">
        <div class="row gy-4">
          <div class="col-md-12">
            <div class="info-box">
              <i class="bi bi-geo-alt"></i>
              <h3>Address</h3>
              <p>
                Sri Rudramsha Hanuman Kshetram, <br />
                Kukatpally, near kukatpally metro,
                Hyderabad, Telangana - 500 072.
              </p>
            </div>

            <br />
            <div>
              <div class="info-box">
                <i class="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <p>+91 70660 06007</p>
              </div>
            </div>
            <br />
            <div>
              <div class="info-box">
                <i class="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <li>
                    <i style="margin-top: 25px" class="fa fa-envelope fa-1"></i>
                    <span  style="margin-right: 7px"></span>
                    <a style="color: #1975bc;font-size: x-large;padding-bottom: 10px;" href="mailto:contact&#64;srirudramshahanumankshetram.org">contact&#64;srirudramshahanumankshetram.org</a>
                  </li>
              </div>
            </div>
            <br />
            <div>
              <div class="info-box">
                <i class="bi bi-clock"></i>
                <h3>Open Hours</h3>
                <p>Monday - Friday<br />9:00AM - 09:00PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row gy-4">
      <div class="col-lg-6">
        <form onsubmit="sendmail();" method="post" class="php-email-form">
          <h3 style="color: #1975bc">Feedback</h3>
          <p></p>
          <div class="row gy-4">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Name</mat-label>
                <input matInput type="text" placeholder="Your Name" required />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  placeholder="Your Email"
                  required
                />
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>Subject</mat-label>
                <input matInput type="text" placeholder="Subject" required />
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <mat-label>Message</mat-label>
                <textarea
                  matInput
                  rows="6"
                  placeholder="Message"
                  required
                ></textarea>
              </mat-form-field>
            </div>
            <h3
              style="
                padding-right: 30px;
                display: table-cell;
                color: #1975bc;
                float: right;
              "
            >
              Send Message
            </h3>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<br />
<br />
<br />
<!-- End Contact Section -->
