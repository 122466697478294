import { Component } from '@angular/core';

@Component({
  selector: 'app-about-ashrama',
  standalone: true,
  imports: [],
  templateUrl: './about-ashrama.component.html',
  styleUrl: './about-ashrama.component.css'
})
export class AboutAshramaComponent {

}
