<br/>
<br/>
<br/>
<div>
    <h5>PRIVACY POLICY</h5>

    <p><b>Sri Rudramsha Hanuman Kshetram</b> takes your privacy seriously and treats all financial information about any transaction you have with the
        Foundation as highly confidential. In addition, <b>Sri Rudramsha Hanuman Kshetram</b> does not share e-mail addresses or phone numbers of any of
        our donors or constituents.</p>
        <br/>
    <b>The Foundation deeply values all contributions to sustain our mission. To protect the privacy of our donors and
        their
        special relationship with <b>Sri Rudramsha Hanuman Kshetram</b>, we maintain the following policies:</b>
        <br/>
        <br/>
    <li>We may request personal information online, such as name, address, phone number, email address, and credit card
        number ONLY for the purposes of accepting donations to <b>Sri Rudramsha Hanuman Kshetram</b>.</li>
    <li>We will not release or use this information for any other purpose unless we have your consent.</li>
    <li>We do not trade or sell your personal information with other organisations.</li>
    <li>We offer donors the option to be recognised anonymously.</li>
    <li>Donors may request, at any time, to not receive our solicitations.</li>
    <li>Donors may request to not receive certain mailings, such as our newsletter.</li>
    <li>We do not track, collect or distribute personal information entered by those who visit our website.</li>
    <li>Personal data collected through our website is encrypted using 256-bit AES (Advanced Encryption Standard)
        technology.</li>
    <li>Personal information stored in <b>Sri Rudramsha Hanuman Kshetram</b> database is protected with a secured login with authentication, assignment
        of a
        unique ID to each person with computer access, regular pass code changes, and user IDs are deactivated or
        terminated
        as needed.</li>
    <li>Our hosting data server provides data protection meeting PCI DSS (Payment Card Industry Data Security Standard),
        encrypted communication via SSL (Secure Sockets Layer) technology, intrusion detection for all devices and
        network
        nodes, state-of-the-art firewall infrastructure that detects malicious application attacks, virus protection,
        network load balancing devices via Citrix, and patch management, security and vulnerability monitoring and
        tracking,
        and SQL server attack protection via applications to detect SQL Injection and Cross Site Scripting Attacks.</li>
        <br/>
        <br/>
        <br/>
    <p><b>To assure that philanthropy merits the respect and trust of the general public, and that donors and prospective
        donors can have full confidence in the not-for-profit organisations and causes they are asked to support, we
        assure
        the following :</b></p>

    <li>To be informed of Sri Rudramsha Hanuman Kshetram mission, of the way Sri Rudramsha Hanuman Kshetram intends to use donated resources, and of its capacity to use
        donations effectively for their intended purposes</li>
    <li>To be informed of the identity of those serving on Sri Rudramsha Hanuman Kshetram governing board, and to expect the board to exercise
        prudent judgment in its responsibilities</li>
    <li>To have access to Sri Rudramsha Hanuman Kshetram most recent financial statements</li>
    <li>To receive appropriate acknowledgment and recognition</li>
    <li>To be assured that information about their donations is handled with respect and with confidentiality to the
        extent
        provided by law</li>
    <li>To expect that all relationships with individuals representing Sri Rudramsha Hanuman Kshetram to the donor will be professional</li>
    <li>To be informed whether those seeking donations are volunteers, employees of the organization or hired solicitors
    </li>
    <li>To have the opportunity for their names to be deleted from mailing lists that Sri Rudramsha Hanuman Kshetram may intend to share</li>
    <li>To feel free to ask questions when making a donation and to receive prompt, truthful and forthright answers</li>
    <br/>
    <br/>
    <b>What information we collect</b>
    <br/>
    <br/>
    <p>We use personal information collected from donors for the purposes of processing payments and communicating with
        donors
        about Sri Rudramsha Hanuman Kshetram as well as conducting the fundraising and other operations of Sri Rudramsha Hanuman Kshetram. This information may include
        name,
        amount donated, address, telephone number, donor comments, e-mail address, and any other personal information
        provided
        to us (“Donor Data”). For donations by check, Donor Data also includes the data visible on the check.</p>

    <p>Information from Payment Processors and Other Service Providers: Payment processors allow donors to give
        electronically
        using a payment services account, a credit card, or other payment method. These processors collect certain
        information
        from donors, and you should consult their privacy policies to determine their practices.</p>

    <p>To provide donors the best possible experience, we work with service providers and may share Donor Data and other
        information with, or have it transmitted through, them. Such service providers include, for example, collocation
        facilities and bandwidth providers as well as organisations that help non-profit organizations with fundraising.
    </p>

    <br/>
    <br/>
    <b>How we use that information</b>
    <br/>
    <br/>
    <p>Donor Data may be used for these kinds of purposes:</p>

    <li>Distributing receipts and thanking donors for donations</li>
    <li>Informing donors about upcoming fundraising and other activities of Sri Rudramsha Hanuman Kshetram</li>
    <li>Internal analysis, such as research and analytics</li>
    <li>Record keeping</li>
    <li>Reporting to applicable government agencies as required by law</li>
    <li>Surveys, metrics, and other analytical purposes</li>
    <li>Other purposes related to the fundraising operations</li>

    <br/>
    <br/>
    <p>Anonymous donor information may be used for promotional and fundraising activities. Comments that are provided by
        donors
        may be publicly published and may be used in promotional materials. We may use available information to
        supplement
        the
        Donor Data to improve the information we use to drive our fundraising efforts. We may allow donors the option to
        have
        their name publicly associated with their donation unless otherwise requested as part of the online donation
        process.</p>

    <p>We use data gathered for payment processors and other service providers only for the purposes described in this
        policy.</p>
        <br/>
    <b>Contact us</b>
    <p>If you have questions about this Donor Privacy Policy or requests about the status and correctness of your donor
        data,
        please contact us at <a style="color: #222;"
        href="mailto:contact&#64;srirudramshahanumankshetram.org"><b>contact&#64;srirudramshahanumankshetram.org</b></a></p>
        <br/>
    <b>Security</b>
    <p>We are committed to protecting donor personal information from unauthorised access, alteration, disclosure, or
        destruction. Among other things, we undertake a range of security practices, including measures to help secure
        web
        access to sensitive data and undertake efforts to address security vulnerabilities for various tools and
        databases.</p>
        <br/>
    <b>Other Disclosures</b>
    <p>We may disclose information when required by law; when needed to protect our rights, privacy, safety, property,
        donors,
        or users; and when necessary to enforce our terms of service.</p>
        <br/>
    <b>Updates</b>
    <p>We may change the Donor Privacy Policy from time to time. Any and all changes will be reflected on this page.
        Substantive changes may also be announced through the standard mechanisms by which we communicate with our users
        and
        community. You should periodically check this page for any changes to the current policy.</p>
        <br/>
    <b>Data Retention</b>
    <p>We seek to retain donor-related information only as needed to fulfil the purposes described in this policy unless
        a
        longer retention period is required by law or regulations. For example, tax laws in the India may require us to
        keep
        contact information and contribution level of donors on file.</p>
        <br/>
    <b>Rights</b>
    <p>You have certain rights with respect to the information we collect about you. Upon request, we will tell you what
        information we hold about you and correct any incorrect information. We will also make reasonable efforts to
        delete your
        information if you ask us to do so, unless we are otherwise required to keep it.</p>
<br/>
<br/>
<br/>
<br/>
<br/>
</div>