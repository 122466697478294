import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.css'
})
export class ContactInformationComponent implements OnInit{


  ngOnInit(): void {
    
  }

  sendmail(){
    
  }
}
