import { Component, OnInit } from '@angular/core';
import { UserSignUp } from '../model/UserSignUp';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonCacheService } from '@srhk/srhk-core-library';
// import { CommonCacheService } from '../shared/service/CommonCacheService';

@Component({
  selector: 'app-singup',
  templateUrl: './singup.component.html',
  styleUrl: './singup.component.scss'
})
export class SingupComponent implements OnInit  {
  userSignUp: UserSignUp;
  userSignUpForm: any = {
    username: null,
    email: null,
    password: null
  };
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  _loaderShow = false;

  public validationErrors = {
    email: {
      'required': 'User name is required',
      'email': 'Invalid email',
    },
    password: {
      'required': 'Password is required',
    },
  };
  constructor(
    private fb: FormBuilder,
    private commonCacheService: CommonCacheService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.userSignUpForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  onSubmit(): void {
    const { username, email, password } = this.userSignUpForm;

  }
}
