import { Component, OnInit, ViewChild } from '@angular/core';
// import { AuthContext } from '../../shared/service/auth.context';
import { ActivatedRoute, Router } from '@angular/router';
// import { AuthService } from '../../shared/service/auth.service';
// import { TokenService } from '../../shared/service/TokenService';
// import { CommonCacheService } from '../../shared/service/CommonCacheService';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { Address, PatientAddress } from '../../model/address';
import { AuthContext, AuthService, CommonCacheService, ToastService, TokenService } from '@srhk/srhk-core-library';
import { ToastrService } from 'ngx-toastr';
import { DonationEnroll } from '../../model/donation';
import { BookAppointmentService } from '../book-appointment/book-appointment.service';
import { UserService } from '../user.service';
import { MatDialog } from '@angular/material/dialog';
import { RazorpayService } from '../razorPay.service';
import { BillPaymentService } from '../billPayment.service';
import { BasicInfoUpdate, LoggedInUserInfo } from '../../model/User';
import { CalculateOrder, CalculateOrderItem } from '../../model/calculateOrder';
import { Order } from '../../model/order';
import { Razorpayment } from '../../model/razorPayment';
import { AddCashReceipt } from '../../model/billCashReceipt';
// import { AddressComponent } from '../address-component/address-component.component';
// import { ToastService } from '../../shared/service/toast.service';

declare var Razorpay: any

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrl: './donation.component.css',
})
export class DonationComponent implements OnInit {

  // @ViewChild(AddressComponent, { static : true })
  // public addressCmpt: AddressComponent;

  appType = "";
  donationRegForm: FormGroup;
  address: Address = new Address();
  donationEnroll: DonationEnroll = new DonationEnroll();
  _loaderShow = false;
  donationAmount = 10;
  donationAmountRO = false;
  donationpurposeRO = false;
  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private toastService: ToastService,
    private toastr: ToastrService,
    private bookAppointmentService: BookAppointmentService,
    private userService: UserService,
    public dialog: MatDialog,
    public razorpayService: RazorpayService,
    public billPaymentService: BillPaymentService,
  ) {

    console.log("-------------", this.authContext.isAuthenticated)

    // if (!this.authContext.isAuthenticated) {
    //   this.tokenService.setHomeDashboardURL('donation')
    //   this.router.navigate(['auth/login'], { replaceUrl: true });
    // }
    // if(this.tokenService.getHomeDashboardURL() !== 'donation'){
    //   this.tokenService.setHomeDashboardURL('donation')
    // }
    // if (this.data[0] && this.data[0].address) {
    //   this.addressComponent?.createAddress(this.data[0].address)
    // } else {
    // this.addressCmpt?.createAddress(new PatientAddress());
    // }
    // = this.addressComponent.getAddress();
    // }


  }

  ngOnInit(): void {

    const userInfo = this.tokenService.getUserInfo();
    this.setLoggedInUser(userInfo);
    this.donationEnroll.purpose = this.tokenService.getDonationPurpose();
    console.log("====getDonationPurpose=====", this.tokenService.getDonationPurpose())
    if (this.tokenService.getDonationPurpose()) {
      this.tokenService.setDonationPurpose("");
      this.donationEnroll.amount = 5000;
      this.donationAmountRO = true;
      this.donationpurposeRO = true;
    }
    // if (userInfo?.user?.address?.length > 0) {
    //   this.addressCmpt?.createAddress(userInfo.user.address[0]);
    // } else {
    //   this.addressCmpt?.createAddress(new PatientAddress());
    // }

    // this.toastService.showSuccess('Resource Modified Successfully', 'Successfully');

  }
  setLoggedInUser(userInfo) {
    this.donationEnroll.firstName = userInfo.user.firstName;
    this.donationEnroll.lastName = userInfo.user.lastName;
    this.donationEnroll.phoneNumber = userInfo?.user?.communicationId?.telephones[0]?.dialNumber;
    this.donationEnroll.userId = userInfo?.user?.communicationId?.emailIds[0]?.uri;

  }

  cancel() {
    console.log("--------cancel----------")
    this.donationEnroll = {} as DonationEnroll;

    this.toastService.showInfo('Donation Form Clear', 'Information');
  }

  successPayment(response: any, order: Order, bookAppointmentSvc: BookAppointmentService,
    razorpaySvc: RazorpayService, billPaymentSvc: BillPaymentService,
    tokenSvc: TokenService, rvtr: Router
  ) {
    order.status.name = "success";
    order.trackingId = response.razorpay_payment_id;
    bookAppointmentSvc.placeOrder(order).subscribe(res => {
      order = res?.data;
      const token = tokenSvc.getToken();
      tokenSvc.saveToken("");
      razorpaySvc.getPaymentDetails(response.razorpay_payment_id)
        .subscribe((razorpaymentResponse: Razorpayment) => {
          console.log("----response------", response)
          order.status.name = "success";
          order.trackingId = response.razorpay_payment_id;

          const cashReceipt: AddCashReceipt = {
            transactionId: response?.razorpay_payment_id,
            orderNumber: order.orderNumber,
            amount: order.totalAmount,
            gst: order.gst,
            shippingCharge: 0,
            discount: 0,
            refNumber: response?.razorpay_payment_id,
            transactionDate: new Date(),
            paymentType: razorpaymentResponse.method,
            bankName: razorpaymentResponse.bank,
            bankAccount: null,
            bankStateBranch: null,
            notes: null,
            receivedDate: new Date(),
            paidByGuestEmail: null,
            paidByUserName: null
          }
          tokenSvc.saveToken(token);
          billPaymentSvc.addGuestCashReceipt(cashReceipt).subscribe((cashReceiptResponse) => {
            tokenSvc.saveRefreshToken(cashReceiptResponse.orderNumber)
            tokenSvc.setHomeDashboardURL('paymentConfirmation')
            rvtr.navigate(['paymentConfirmation']);
          }, (error) => {
            console.log("--1--err------", error)
          })
        }, (err) => {
          console.log("--2--err------", err)
        });
    }, (err) => {
      console.log("--3--err------", err)
    })
  }

  cancelledPayment(order: Order, bookAppointmentService: BookAppointmentService, toastSvc: ToastService) {
    bookAppointmentService.placeOrder(order).subscribe(res => {
      console.log("cancelledPayment  success", res)
      window.location.reload();
      toastSvc.showError("Payment Cancelled", "Error")
    }, (err) => {
      console.log("-cancelledPayment-err--", err)
      window.location.reload();
      toastSvc.showError("Payment Cancelled update", "Error")
    });
  }

  onFailedPayment(failedResponse: any, order: Order,
    bookAppointmentSvc: BookAppointmentService,
    toastSvc: ToastService,
    tokenSvc: TokenService,
    rvtr: Router
  ) {
    order.status.name = "failed";
    order.trackingId = failedResponse.error.metadata.payment_id;
    bookAppointmentSvc.placeOrder(order).subscribe(res => {
      tokenSvc.saveRefreshToken(order.orderNumber)
      tokenSvc.setHomeDashboardURL('paymentFailed')
      rvtr.navigate([`/paymentFailed`, { queryParams: { orderNumber: `${order.orderNumber}` } }]);
    }, (err) => {
      console.log("An Error", err)
    });
  }


  submit() {

    // const BasicInfoUpdateBody: BasicInfoUpdate = {
    //   id: this.tokenService.getUserInfo()?.user?.id,
    //   firstName: this.donationEnroll.firstName,
    //   lastName: this.donationEnroll.lastName,
    //   mobileNumber: this.donationEnroll.phoneNumber,
    //   emailId: this.donationEnroll.userId
    // }

    const calculateOrderItem: CalculateOrderItem = {
      id: null,
      name: "appointment",
      description: this.donationEnroll.purpose,
      image: null,
      sku: "Donation",
      taxCategoryCode: null,
      quantity: 1,
      productId: null,
      price: this.donationEnroll.amount,
      gst: null,
      discountAmount: 0
    }
    const calculateOrder: CalculateOrder = {
      id: 0,
      orderNumber: null,
      orderItem: [calculateOrderItem],
      status: {
        id: null,
        name: "pending",
        createdDate: new Date(),
        updatedDate: new Date()
      },
      user: {
        firstName: this.donationEnroll.firstName,
        lastName: this.donationEnroll.lastName,
        status: "active",
        communicationId: {
          emailIds: [
            {
              type: "p",
              uri: this.donationEnroll?.userId,
              notificationIndicator: true,
              id: uuidv4
            }
          ],
          telephones: [{
            countryDialing: "+91",
            notificationIndicator: true,
            dialNumber: Number(this.donationEnroll?.phoneNumber),
            formattedNumber: '+91 '+this.donationEnroll?.phoneNumber,
            type: 'P',
            areaDialing: Number(this.donationEnroll?.phoneNumber.substring(0,4)),
            extension: '',
            id: 0
          }]
        },
        id: 0,
        middleName: '',
        address: [null],
        createdDate: new Date(),
        updatedDate: new Date(),
      }
    }

    // this.userService.UpdateBasicInfo(BasicInfoUpdateBody).subscribe((user: LoggedInUserInfo) => {

    this.bookAppointmentService.calculate(calculateOrder).subscribe((calculateOrder: Order) => {
      console.log("An order ", JSON.stringify(calculateOrder));

      this.bookAppointmentService.placeOrder(calculateOrder).subscribe(placeOrder => {
        console.log(placeOrder);
        let inProgressOrder = placeOrder.data;
        this.tokenService.saveUserInfo(inProgressOrder?.user);
        if (inProgressOrder) {
          const successPayment = this.successPayment;
          const cancelledPayment = this.cancelledPayment;
          const bookAppointmentSvc = this.bookAppointmentService;
          const onFailedPayment = this.onFailedPayment;
          const toastSvc = this.toastService;
          const razorpaySvc = this.razorpayService;
          const billPaymentSvc = this.billPaymentService;
          const tokenSvc = this.tokenService;
          const rvtr = this.router;

          const option = {
            key: "rzp_live_Xgcf2DkS2lOVUa",
            amount: Number(inProgressOrder.totalAmount) * 100,
            name: this.donationEnroll.firstName + " " + this.donationEnroll.lastName,
            description: this.donationEnroll.purpose,
            image: "",
            orderId: inProgressOrder.orderNumber,
            handler: function (response) {
              console.log("222@@@@@@success--", response);
              successPayment(response, inProgressOrder, bookAppointmentSvc, razorpaySvc,
                billPaymentSvc, tokenSvc, rvtr
              )
            },
            prefill: {
              name: this.donationEnroll.firstName + " " + this.donationEnroll.lastName,
              email: this.donationEnroll.userId,
              contact: this.donationEnroll.phoneNumber
            },
            theme: {
              colour: "#3399cc"
            },
            modal: {
              ondismiss: function (this) {
                console.log('Checkout form closed');
                inProgressOrder.status.name = "cancelled";
                cancelledPayment(inProgressOrder, bookAppointmentSvc, toastSvc)

              }
            }
          };
          var rzp1 = new Razorpay(option);
          rzp1.open();
          rzp1.on('payment.failed', function (failedResponse) {
            rzp1.close()
            onFailedPayment(failedResponse, inProgressOrder, bookAppointmentSvc, toastSvc, tokenSvc, rvtr)
          })
        }
      });

    }, (error) => {
      console.log("An error ocured", error);
      this._loaderShow = false;
      this.toastService.showError("An error ocured", "Error");
    })
    // }, (err) => {
    //   console.log("An error ocured", err);
    //   this._loaderShow = false;
    //   this.toastService.showError("An error ocured", "Error");
    // })

  }
}
