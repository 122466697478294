import { NgModule } from "@angular/core";
import { FeaturesComponent } from "./features.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { LandingDashboardComponent } from "./landing-dashboard/landing-dashboard.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatTableModule } from "@angular/material/table";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogTitle } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CommonModule } from "@angular/common";
import { LogoutComponent } from "./logout/logout.component";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DonationComponent } from "./donation/donation.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SnackbarComponent } from "../shared-component/snackbar/snackbar.component";
import { AlertComponent } from "../shared-component/alert/alert.component";
import { AlertMessageComponent } from "../shared-component/alert-message/alert-message.component";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
// import { ToastService } from "../shared/service/toast.service";
// import { AuthContext } from "../shared/service/auth.context";
// import { TokenService } from "../shared/service/TokenService";
// import { CommonCacheService } from "../shared/service/CommonCacheService";
import { ToastrModule } from "ngx-toastr";
import { SrhkCoreLibraryModule } from "@srhk/srhk-core-library";
import { BookAppointmentComponent } from "./book-appointment/book-appointment.component";
import { MatMomentDateModule, MomentDateModule } from "@angular/material-moment-adapter"
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule, } from '@angular-material-components/datetime-picker';
import { MatNativeDateModule } from "@angular/material/core";
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { BookAppointmentConfirmationComponent } from "./book-appointment-confirmation/book-appointment-confirmation.component";
import { PaymentConfirmationComponent } from "./payment-confirmation/payment-confirmation.component";
import { PaymentFailedComponent } from "./payment-failed/payment-failed.component";
import { AddressComponent } from "./address-component/address-component.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { UserBookingComponent } from "./user-booking/user-booking.component";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AgGridModule } from "ag-grid-angular";
export const MOMENT_DATETIME_WITH_SECONDS_FORMAT = 'DD/MM/YYYY HH:mm:ss';

const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
  },
  display: {
    dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconButton,
    BrowserAnimationsModule,
    CommonModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    ToastrModule,
    SrhkCoreLibraryModule,
    MomentDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatNativeDateModule, MatMomentDateModule,
    MatSortModule, MatPaginatorModule, AgGridModule,
    
    RouterModule.forChild([
      {
        path: "dashboard",
        component: LandingDashboardComponent,
        data: { title: "Dashboard" },
      },
      { path: "donation", component: DonationComponent },
      { path: "logout", component: LogoutComponent },

      { path: 'bookAppointment', component: BookAppointmentComponent },
      { path: 'paymentConfirmation', component: PaymentConfirmationComponent },
      { path: 'paymentFailed', component: PaymentFailedComponent },
      { path: 'userProfile', component: UserProfileComponent },

      { path: 'userOrder', component: UserBookingComponent },
      
      

    ]),
  ],
  declarations: [
    FeaturesComponent,
    LandingDashboardComponent,
    LogoutComponent,
    DonationComponent,
    AddressComponent,
    SnackbarComponent,
    AlertComponent,
    AlertMessageComponent,
    BookAppointmentComponent,
    BookAppointmentConfirmationComponent,
    PaymentConfirmationComponent,
    PaymentFailedComponent,
    UserProfileComponent,
    UserBookingComponent,
  ],
  exports: [LandingDashboardComponent],
  providers: [
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ],
})
export class FeaturesModule { }
