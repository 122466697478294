<div>
<br/>
<br/>
<b>Refund and cancellation</b>
<br/>
<br/>
   
<p>Our policy on refund and cancellation of donations received for <b>Sri Rudramsha Hanuman Kshetram</b> activities on secure online payment gateway as under:</p>
<br/>
<br/>

<p>
Any request for cancellations and refund of online donations once duly placed on the website, shall not be entertained under any circumstances. No cash or refund of money will be allowed after completing the online donation as it is an extremely cumbersome process. We therefore request you to be sure before you donate.
</p>

<br/>
<br/><br/>
<br/>
</div>