import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
  styleUrl: './landing-dashboard.component.css'
})
export class LandingDashboardComponent {

}
