export class Address {
    id?: number
    type: string = 'P';
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    subDivision1: string;
    subDivision2: any
    city: string;
    country: any
    postalCode: string;
    geoCoordinates: string;
    formatedAddress: string;

}

export class PincodeDivisions {
    id: number;
	circleName: string;
	divisionName: string;
	officeName: string;
	districtCode: string;
    stateCode: string;
    pincode: string;
    stateFullName: string;
    disctrictFullName: string;
}


export class PatientAddress {
    id: number | null;
    type: string | null;
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    city: string;
    subDivision1: string; //state
    subDivision2: string; //District
    country: string;
    postalCode: string;

    constructor(id = null,
        type = '',
        lineOne = '',
        lineTwo = '',
        lineThree = '',
        city = '',
        subDivision1 = '',//state
        subDivision2 = '',//District
        country = '',
        postalCode = null,) {
        this.id = id;
        this.type = type;
        this.lineOne = lineOne;
        this.lineTwo = lineTwo;
        this.lineThree = lineThree;
        this.city = city;
        this.subDivision1 = subDivision1; //state
        this.subDivision2 = subDivision2; //District
        this.country = country;
        this.postalCode = postalCode;
    }
}
