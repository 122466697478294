<div id="contact">
    <form #f="ngForm" (ngSubmit)="submit()">
        <div class="row gy-12">
            <h1 style="color: #1975bc">Donation Basic Details</h1>
            <div>
                <ul>
                    <li>This donation will allow you to connect with <b>Guru Viraat</b> or to sponser any social
                        activities.</li>
                    <li>Once you donate Amount and complete the payment, our representative will contact you
                        based on the details you
                        provide, so please ensure your contact information is accurate and up-to-date.</li>
                    <li>Non-refundable/cancellation policies for donation are as follows:
                        <ol>
                            <li><b>Non-Refundable Policy:</b> donation is non-refundable.</li>
                            <li>Access to all our services is available exclusively through donations.</li>
                        </ol>

                        <br />
                        <p>Please refer to our detailed cancellation policies for specific terms and conditions.</p>
                    </li>

                </ul>
                <br />
            </div>
            <div class="row gy-4">
                <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" name="firstName" placeholder="First Name"
                        [(ngModel)]="donationEnroll.firstName" required>
                    <mat-error>
                        First Name Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" placeholder="Last Name" name="lastName"
                        [(ngModel)]="donationEnroll.lastName" required>
                    <mat-error>
                        Last Name Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" placeholder="Email Id" name="userId"
                        [(ngModel)]="donationEnroll.userId">
                    <mat-error>
                        Email Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                    <span matPrefix>+91 &nbsp;</span>
                    <mat-label>Phone Number</mat-label>
                    <input matInput type="text" placeholder="Phone Number" name="phoneNumber"
                        [(ngModel)]="donationEnroll.phoneNumber" required>
                    <mat-error>
                        Phone Number Cannot be empty!
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                    <mat-label>Donation Purpose</mat-label>
                    <input matInput type="text" placeholder="Donation Purpose" name="purpose"
                        [(ngModel)]="donationEnroll.purpose" required [readonly]="donationpurposeRO">
                    <mat-error>
                        Donation Purpose Cannot be empty!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                    <mat-label>Donation Amount</mat-label>
                    <input matInput type="number" min="donationAmount" placeholder="Donate Amount" name="amount"
                        [(ngModel)]="donationEnroll.amount" required [readonly]="donationAmountRO">
                    <mat-error>
                        Donation Amount should be minimun of {{donationAmount}} INR
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row gy-12">
            <!-- <h3 style="color: #1975bc">Communication Details</h3>
        <srhk-address-component></srhk-address-component>
        <br /> -->
            <div style="float: right;">
                <button mat-raised-button style="margin: 6px;" (click)="cancel()"> Cancel </button>
                <button mat-raised-button style="margin: 6px;" color="primary" type="submit" [disabled]="!f.valid">
                    Proceed </button>
            </div>
        </div>
    </form>
</div>
<br />
<br />
<br />