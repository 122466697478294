import { Component, OnInit, inject } from '@angular/core';
// import { AuthContext } from '../../shared/service/auth.context';
import { ActivatedRoute, Router } from '@angular/router';
// import { AuthService } from '../../shared/service/auth.service';
// import { TokenService } from '../../shared/service/TokenService';
import {
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarHorizontalPosition,
  MatSnackBarLabel,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthContext, AuthService, TokenService } from '@srhk/srhk-core-library';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.css',
})
export class LogoutComponent implements OnInit {
  durationInSeconds = 5;
  snackBarRef;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService
  ) {
    this.snackBarRef = inject(MatSnackBarRef);
  }

  ngOnInit(): void {
    setTimeout(() => this.logout(), 0);
  }

  logout() {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    this.tokenService.removeUserInfo();
    this.authContext.removeIsAuthenticated();
    window.sessionStorage.clear();
    this.router.navigate(['../'], { replaceUrl: true });
    window.location.reload();
    
  }

  
}
