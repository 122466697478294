import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'hcn-validation-error',
    template: `
            <span class="error-text" *ngIf="isAnyValidationError">{{errorMessage}} </span>
    `,
})
export class ValidationErrorComponent {
    @Input() formControl: FormControl;
    @Input() validationErrors: any;
    @Input() isFormSubmitted: boolean;

    public get isAnyValidationError(): boolean {
        return this.formControl.invalid && (this.isFormSubmitted || this.formControl.touched || this.formControl.dirty);
    }

    public get errorMessage(): string {
        const errors = this.formControl.errors;
        if (errors != null) {
            return this.validationErrors[Object.keys(errors)[0]];
        }
        return '';
    }
}
